import xHttp from '@utils/xHttp';
import { getBaseApiUrl } from '@utils/config';

/**
 * get dealers near a postal code within a specified radius
 * @param {string} postalCode the postal code
 * @param {number} radiusInMiles the radius from the postal code to look in miles
 * @param {boolean} expandSearch whether the search should expand to 200 miles if only one retailer is returned
 */
export const getNearbyDealers = (postalCode, radiusInMiles, expandSearch) => {
  const url = `${getBaseApiUrl()}dealers/?postalCode=${postalCode}&radiusInMiles=${radiusInMiles}&expandSearch=${
    expandSearch || false
  }`;
  return xHttp.get(url);
};

/**
 * Get all the dealer listings the country
 * @returns {Promise}
 */
export const getCityStateDealerListings = () => {
  const url = `${getBaseApiUrl()}citystatedealerlistings`;
  return xHttp.get(url);
};

/**
 * Get dealer listings for a state
 * @param stateAbbreviation abbrevation for the state
 * @returns {Promise}
 */
export const getCityStateDealerListingsByState = (stateAbbreviation) => {
  const url = `${getBaseApiUrl()}citystatedealerlistings?stateAbbreviation=${stateAbbreviation}`;
  return xHttp.get(url);
};

/**
 * Get dealer listings for all states
 * @returns {Promise}
 */
export const getCityStateDealerListingsNationwide = () => {
  const url = `${getBaseApiUrl()}citystatedealerlistings`;
  return xHttp.get(url);
};

/**
 * Get dealer landing page info by dealerId
 * @param {string} navigationSlug slug for the dealer
 * @returns {Promise}
 */
export const getDealerLandingPageInfo = (navigationSlug, featuredModelId) => {
  let url = `${getBaseApiUrl()}dealerlandingpages?dealerSlug=${navigationSlug}`;
  if (featuredModelId && featuredModelId > 0) {
    url += `&featuredModelId=${featuredModelId}`;
  }
  return xHttp.get(url);
};

/**
 * Get the available models for a dealer.
 * @param {string} dealerSlug slug for the dealer
 * @param {number} startIndex The first model from the retrieved models to start at prior to pagination.
 * @param {number} modelsPerPage models to display per load
 * @returns {Promise}
 */
export const getDealerAvailableModels = ({
  dealerSlug = '',
  startIndex = 0,
  modelsPerPage = 0,
}) => {
  const url = `${getBaseApiUrl()}dealerLandingModels?dealerSlug=${dealerSlug}&startIndex=${startIndex}&modelsPerPage=${modelsPerPage}`;
  return xHttp.get(url);
};

/**
 * Get the available inventory for a dealer.
 * @param {string} dealerSlug slug for the dealer
 * @param {number} startIndex The first model from the retrieved models to start at prior to pagination.
 * @param {number} modelsPerPage models to display per load
 * @returns {Promise}
 */
export const getDealerInventoryModels = ({
  dealerSlug = '',
  startIndex = 0,
  modelsPerPage = 0,
}) => {
  const url = `${getBaseApiUrl()}dealerLandingInventory?dealerSlug=${dealerSlug}&startIndex=${startIndex}&modelsPerPage=${modelsPerPage}`;
  return xHttp.get(url);
};

// export const getDealersWhichSellNearGeolocation = (modelId, latitude, longitude) => {
//     if (!modelId) {
//         throw new Error("modelId is required.");
//     }
//     const locParams = latitude && longitude ? `&latitude=${latitude}&longitude=${longitude}` : null;
//     let url = `${getBaseApiUrl()}api/modelDealers?modelId=${modelId}`;
//     if (locParams) {
//         url += locParams;
//     }
//     return xHttp.get(url, true);
// };

/**
 * Get dealer landing page info by dealerId
 * @param {string} dealerId for the dealer
 * @returns {Promise}
 */
export const getDealerLandingPageInfoById = (dealerId) => {
  let url = `${getBaseApiUrl()}dealerlandingpages?dealerId=${dealerId}`;
  return xHttp.get(url);
};
